'use client';

import { timeout } from "@/components/private/actions/timeout";
import { submitRating } from "@/app/actions/rating";
import { Star } from "@/components/private/star";
import { useState } from "react";

export default function VideoRatingClient({ videoId, current: initial, className, }: { videoId: number, current: number, className: string }) {
  const [msg, setMsg,] = useState<string>(``);
  const [current, setCurrent,] = useState(initial);

  function onMouseEnter(n: number) {
    Array.from(Array(5).keys()).map((i) => {
      const star = document.querySelector(`.rating-star-${i}`);
      if (i < n) {
        star?.classList.add(`active`);
      } else {
        star?.classList.remove(`active`);
      }
    });
  }

  function onMouseLeave() {
    Array.from(Array(5).keys()).map((i) => {
      const star = document.querySelector(`.rating-star-${i}`);
      if (i < current) {
        star?.classList.add(`active`);
      } else {
        star?.classList.remove(`active`);
      }
    });
  }

  function onClick(rating: number) {
    setMsg(`voted`);
    const timeoutPromise = timeout(2000);
    Promise.all([
      submitRating(videoId, rating),
      timeoutPromise,
    ]).then(([r,]) => {
      setCurrent(r);
      setMsg(``);
    }).catch(() => {
      timeoutPromise.then(() => setMsg(``));
    });
  }

  return (
    <div className={className} title={`rating: ${current}`} >
      {msg ? <p>{msg}</p> : <>
        <Star onClick={() => onClick(1)} onMouseEnter={() => onMouseEnter(1)} onMouseLeave={() => onMouseLeave()} className={`cursor-pointer rating-star-0 ${current > 0 ? `active` : ``}`} />
        <Star onClick={() => onClick(2)} onMouseEnter={() => onMouseEnter(2)} onMouseLeave={() => onMouseLeave()} className={`cursor-pointer rating-star-1 ${current > 1 ? `active` : ``}`} />
        <Star onClick={() => onClick(3)} onMouseEnter={() => onMouseEnter(3)} onMouseLeave={() => onMouseLeave()} className={`cursor-pointer rating-star-2 ${current > 2 ? `active` : ``}`} />
        <Star onClick={() => onClick(4)} onMouseEnter={() => onMouseEnter(4)} onMouseLeave={() => onMouseLeave()} className={`cursor-pointer rating-star-3 ${current > 3 ? `active` : ``}`} />
        <Star onClick={() => onClick(5)} onMouseEnter={() => onMouseEnter(5)} onMouseLeave={() => onMouseLeave()} className={`cursor-pointer rating-star-4 ${current > 4 ? `active` : ``}`} />
      </>}
    </div>
  );
}
