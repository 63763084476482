'use client';

import { ModelTag } from '@/shared/src';
import { useRouter } from 'next/navigation';

export default function SelectModelTagsClient({
  path,
  placeholder,
  searchParams,
  className,
  tags,
}: {
  path: string,
  placeholder: string,
  searchParams: any
  className: any
  tags: ModelTag[]
}) {
  const router = useRouter();
  const urlSearchParams = new URLSearchParams(searchParams);
  const tagsParam = urlSearchParams.get(`mt`) || undefined;
  const current = tagsParam?.split(`,`) || [];

  function onChange(event: any) {
    current.push(event.target.value);
    urlSearchParams.set(`mt`, current.join(`,`));
    router.push(`${path}?${urlSearchParams.toString()}`);
  }

  const tagsToShow = tags.filter((t) => !current.includes(t.attributes.name));

  if (tagsToShow.length > 0) {
    return (
      <select className={className} onChange={onChange} name="selectModelTags" id="selectModelTags">
        <option value="" hidden>{placeholder}</option>
        {tagsToShow.map((t) => <option key={t.attributes.name} value={t.attributes.name}>{t.attributes.name}</option>)}
      </select>
    );
  }

  return <></>;
}
