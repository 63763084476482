'use client';

import { useRouter } from "next/navigation";

export default function ActiveVideoTagsClient({
  path,
  searchParams,
  className,
}: {
  path: string,
  searchParams: any
  className: any
}) {
  const router = useRouter();
  const urlSearchParams = new URLSearchParams(searchParams);
  const tagsParam = urlSearchParams.get(`vt`) || undefined;

  if (!tagsParam) {
    return <></>;
  }

  const tags = tagsParam?.split(`,`);

  function onClick(tag: string) {
    const current = tags.filter(c => c !== tag);
    if (current.length === 0) {
      urlSearchParams.delete(`vt`);
    } else {
      urlSearchParams.set(`vt`, current.join(`,`));
    }
    router.push(`${path}?${urlSearchParams.toString()}`);
  }

  return <div className={className}>{tags.map((tag, index) => {
    const parts = tag.split(`:`);
    return <div className='cursor-pointer' onClick={() => onClick(tag)} key={index}>{`${parts[0]}${parts[1] ? ` - ${parts[1]}` : ``}`} &times;</div>;
  })}</div>;
}
