'use client';

import { setCookie } from '@/app/actions/cookies';
import { getClasses } from '@/classes';
import { Component, StyledComponent } from '@/shared';
import { modalHide, modalShow } from './private/actions/modal';

export interface ModalButtonComponent extends StyledComponent {
  modal: string
  action: string
  text: string
}

function checkModalButtonComponent(component: Component): ModalButtonComponent {
  const { modal, action, text, ...props } = component as ModalButtonComponent;

  if (!text) {
    throw new Error(`no text in modal-button component`);
  }

  if (!modal) {
    throw new Error(`no modal in modal-button component`);
  }

  if (!action) {
    throw new Error(`no action in modal-button component`);
  }

  return {
    ...props,
    text,
    modal,
    action,
  };
}

export default function ModalButton({ component, }: { component: Component }) {
  const { modal, action, text, styles: { data: styles, }, } = checkModalButtonComponent(component);

  return (
    <button className={getClasses(styles)} onClick={() => {
      switch (action) {
      case `close`:
        modalHide(modal);
        break;

      case `close persist`:
        setCookie(`${modal}-closed`, `true`);
        modalHide(modal);
        break;

      default:
        modalShow(modal);
        break;
      }
    }}>{text}</button>
  );
}
