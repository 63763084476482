'use client';

const classes = {
  column2: `w-1/2`,
  column3: `w-1/3`,
  column4: `w-1/4`,
  column5: `w-1/5`,
  column6: `w-1/6`,
};

export default function Masonry({ documentWidth, children, }: { documentWidth: number, children: any[] }) {
  const colCount = Math.min(6, columnCount(documentWidth));

  const columns = Array(colCount)
    .fill(0)
    .map((_, colIdx) => {
      const result = [];
      for (let eleIdx = colIdx; eleIdx < children.length; eleIdx = eleIdx + colCount) {
        result.push(children[eleIdx]);
      }
      return result;
    });
  return (
    <div className='flex flex-row flex-nowrap' style={{ width: `100%`, }}>
      {columns.map((column, index) => {
        return (
          <div key={index} className={classes[`column${colCount}` as keyof typeof classes]}>
            {column}
          </div>
        );
      })}
    </div>
  );
}

function columnCount(documentWidth: number) {
  if (documentWidth <= 40 * 16) {
    return 2;
  } else if (documentWidth <= 64 * 16) {
    return 3;
  } else if (documentWidth <= 90 * 16) {
    return 5;
  } else {
    return 6;
  }
}
