'use client';

import { modalShow } from "./actions/modal";

export default function VideoLockedOverlay() {
  return (
    <div
      onClick={() => modalShow(`locked`)}
      style={{
        position: `absolute`,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,

        display: `flex`,
        flexDirection: `column`,
        justifyContent: `center`,
        alignItems: `center`,
      }}>
      <svg className='absolute' style={{
        width: `30%`,
        height: `30%`,
      }} viewBox="0 0 48 48" fill="#fff">
        <path d="M14 15.06V33a2 2 0 002.93 1.77l17.09-8.97a2 2 0 000-3.54L16.93 13.3A2 2 0 0014 15.06z"></path>
      </svg>
    </div>
  );
}
