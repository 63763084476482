'use client';

import { ALPHABETHICAL, NEWEST } from '@/constants';
import { useRouter } from 'next/navigation';

export default function SortModelsClient({
  path,
  searchParams,
  className,
}: {
  path: string,
  searchParams: any
  className: any
}) {
  const router = useRouter();
  const urlSearchParams = new URLSearchParams(searchParams);
  const current = urlSearchParams.get(`ms`) || undefined;

  function onChange(event: any) {
    urlSearchParams.set(`ms`, event.target.value);
    router.push(`${path}?${urlSearchParams.toString()}`);
  }

  return (
    <select className={className} defaultValue={current} onChange={onChange} name="modelSort" id="modelSort">
      <option value={ALPHABETHICAL}>{ALPHABETHICAL}</option>
      <option value={NEWEST}>{NEWEST}</option>
    </select>
  );
}
