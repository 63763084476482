'use client';

import { ReactNode } from "react";

export default function NavClient({ children, }: { children: ReactNode }) {
  const handleClick = (event: any) => {
    event.currentTarget.classList.toggle(`is-active`);
    const nav = document.querySelector(`.hamburger-menu`) as HTMLElement;
    if (nav) {
      nav.classList.toggle(`open`);
    }
  };

  return (
    <>
      <div onClick={handleClick} className="hamburger">
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
      <div className="hamburger-menu">
        <div className="hamburger-menu-content">
          {children}
        </div>
      </div>
    </>
  );
}
