'use client';

import { ReactNode } from "react";
import { modalHide } from "./components/private/actions/modal";

export default function LockedModalClient({ inner, }: { inner: ReactNode }) {
  return (
    <div className='locked-modal hidden'
      onClick={() => modalHide(`locked`)}
      style={{
        position: `fixed`,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 1000,
        justifyContent: `center`,
        alignItems: `center`,
      }}
    >
      <div
        onClick={event => event.stopPropagation()} className='locked-modal-content'>
        {inner}
      </div>
      <div className="absolute" style={{
        top: `.25em`,
        right: `.25em`,
        fontSize: `2em`,
        cursor: `pointer`,
      }}>&times;</div>
    </div>
  );
}
