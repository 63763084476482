/* eslint-disable @next/next/no-img-element */
'use client';

import React, { useEffect, useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Masonry from './masonry';
import { modalShow } from './actions/modal';

export default function Gallery({ images, locked, }: { images: { alt: string, original: string, thumbnail: string }[], locked: boolean }) {
  const [documentWidth, setDocumentWidth,] = useState(typeof window !== `undefined` ? window?.innerWidth || document?.documentElement?.clientWidth || document?.body?.clientWidth : 1920);
  const [photoIndex, setPhotoIndex,] = useState(-1);
  const reactImageGalleryRef = useRef(null);

  useEffect(() => {
    window.addEventListener(`resize`, () => {
      setDocumentWidth(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
    });
  });

  if (!images || images.length === 0) {
    return null;
  }

  if (locked) {
    return <Masonry documentWidth={documentWidth}>
      {images.map((image, index) => (
        <div key={index} onClick={() => modalShow(`locked`)}>
          <div className="gallery-image m-px">
            <img
              alt={image.alt}
              src={image.thumbnail}
              width="100%"
            />
          </div>
        </div>
      ))}
    </Masonry>;
  }

  return (
    <>
      <Masonry documentWidth={documentWidth}>
        {images.map((image, index) => (
          <div key={index} onClick={() => {
            if (locked) {
              modalShow(`locked`);
            } else {
              setPhotoIndex(index);
              (reactImageGalleryRef as any).current.toggleFullScreen();
            }
          }}>
            <div className="gallery-image m-px">
              <img
                alt={image.alt}
                src={image.thumbnail}
                width="100%"
              />
            </div>
          </div>
        ))}
      </Masonry>
      <div className={photoIndex > -1 ? `` : `hidden`}>
        <ImageGallery
          thumbnailPosition={documentWidth > 600 ? `left` : undefined}
          lazyLoad={true}
          showNav={true}
          showThumbnails={documentWidth > 600}
          showPlayButton={true}
          showFullscreenButton={true}
          items={images}
          onSlide={index => {
            setPhotoIndex((index + images.length) % images.length);
          }}
          startIndex={photoIndex}
          ref={reactImageGalleryRef}
          onScreenChange={onScreenChange}
        />
      </div>
    </>
  );

  function onScreenChange(isFullscreen: boolean) {
    if (!isFullscreen) {
      setPhotoIndex(-1);
    }
  }
}
