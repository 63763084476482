'use client';

import { unlockVideo } from "@/app/actions/unlock";
import { MouseEvent, useState } from "react";

export default function UnlockVideoButton({ brandId, slug, }: { brandId: number, slug: string }) {
  const [isDisabled, setIsDisabled,] = useState(false);
  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    setIsDisabled(true);
    unlockVideo(brandId, slug);
  };

  return <a href='#' style={isDisabled ? {
    pointerEvents: `none`,
    cursor: `default`,
  } : {}} onClick={handleClick}>UNLOCK</a>;
}
