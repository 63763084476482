'use client';

import { NEWEST, RATING } from '@/constants';
import { useRouter } from 'next/navigation';

export default function SortVideosClient({
  path,
  searchParams,
  className,
}: {
  path: string,
  searchParams: any
  className: any
}) {
  const router = useRouter();
  const urlSearchParams = new URLSearchParams(searchParams);
  const current = urlSearchParams.get(`vs`) || undefined;

  function onChange(event: any) {
    urlSearchParams.set(`vs`, event.target.value);
    router.push(`${path}?${urlSearchParams.toString()}`);
  }

  return (
    <select className={className} defaultValue={current} onChange={onChange} name="videoSort" id="videoSort">
      <option value={NEWEST}>{NEWEST}</option>
      <option value={RATING}>{RATING}</option>
    </select>
  );
}
