export const DEFAULT_REVALIDATE_FOR_CONTENT = process.env.DEFAULT_REVALIDATE_FOR_CONTENT
  ? Number.parseInt(process.env.DEFAULT_REVALIDATE_FOR_CONTENT)
  : 60;

export const IMGPROXY_EXPIRES_IN = process.env.IMGPROXY_EXPIRES_IN
  ? Number.parseInt(process.env.IMGPROXY_EXPIRES_IN)
  : 10 * 60; // 10 minutes
export const ALPHABETHICAL = `alphabethical`;
export const NEWEST = `newest`;
export const RATING = `rating`;
export const OLDEST = `oldest`;
