'use client';

import { signIn } from "next-auth/react";
import { MouseEvent, useState } from "react";

export default function LoginButton() {
  const [isDisabled, setIsDisabled,] = useState(false);
  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    setIsDisabled(true);
    signIn();
  };

  return <a href='#' style={isDisabled ? {
    pointerEvents: `none`,
    cursor: `default`,
  } : {}} onClick={handleClick}>LOGIN</a>;
}
