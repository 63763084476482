'use client';

import { ReactNode } from "react";
import { modalHide } from "./actions/modal";

export default function ModalClient({ children, identifier, active, closable, }: { children: ReactNode, identifier: string, active: boolean, closable: boolean }) {
  return (
    <div className={`${identifier}-modal ${active ? `` : `hidden`}`}
      onClick={() => closable && modalHide(identifier)}
      style={{
        position: `fixed`,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 1000,
        justifyContent: `center`,
        alignItems: `center`,
      }}
    >
      <div
        onClick={event => event.stopPropagation()} className={`${identifier}-modal-content`}>
        {children}
      </div>
      {closable && <div className="absolute" style={{
        top: `.25em`,
        right: `.25em`,
        fontSize: `2em`,
        cursor: `pointer`,
      }}>&times;</div>}
    </div>
  );
}
