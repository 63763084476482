'use client';

import { useRouter } from 'next/navigation';
import { useState } from 'react';

export default function SearchClient({ path, searchParams, }: { path: string, searchParams: any }) {
  const router = useRouter();
  const urlSearchParams = new URLSearchParams(searchParams);
  const [search, setSearch,] = useState(urlSearchParams.get(`q`) || ``);

  function onAction() {
    if (search) {
      urlSearchParams.set(`q`, search);
    } else {
      urlSearchParams.delete(`q`);
    }
    router.push(`${path}?${urlSearchParams.toString()}`);
  }

  return (
    <form action={onAction}>
      <input className='search' type="text" value={search} onChange={e => setSearch(e.target.value)} />
      <input className='submit' type="submit" value='Search'/>
    </form>
  );
}
