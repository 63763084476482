'use client';

import { Component, StyledComponent } from '@/shared';
import { Session } from "next-auth";
import { signIn } from "next-auth/react";
import { getClasses } from "@/classes";

export interface LoginComponent extends StyledComponent {
  label: string,
}

function checkLoginComponent(component: Component): LoginComponent {
  const { label, ...props } = component as LoginComponent;

  if (!label) {
    throw new Error(`no label in login component`);
  }

  return {
    ...props,
    label,
  };
}

export default function Login({ component, session, }: { component: Component, session: Session | null }) {
  const { label, styles: { data: styles, }, } = checkLoginComponent(component);

  return !session && <a className={`cursor-pointer ${getClasses(styles)}`} onClick={() => signIn()}>{label}</a>;
}
