'use client';

import { Component, StyledComponent } from '@/shared';
import { Session } from "next-auth";
import { signOut } from "next-auth/react";
import { getClasses } from "@/classes";

export interface LogoutComponent extends StyledComponent {
  label: string,
}

function checkLogoutComponent(component: Component): LogoutComponent {
  const { label, ...props } = component as LogoutComponent;

  if (!label) {
    throw new Error(`no label in logout component`);
  }

  return {
    ...props,
    label,
  };
}

export default function Logout({ component, session, }: { component: Component, session: Session | null }) {
  const { label, styles: { data: styles, }, } = checkLogoutComponent(component);

  return session && <a className={`cursor-pointer ${getClasses(styles)}`} onClick={() => signOut()}>{label}</a>;
}
