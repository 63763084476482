export const modalHide = (identifier: string) => {
  const className = `${identifier}-modal`;
  const modal = document.querySelector(`.${className}`);
  if (modal) {
    modal.classList.add(`hidden`);
    modal.classList.remove(`block`);
  } else {
    console.warn(`Unable to hide modal '${className}', not found`);
  }
};

export const modalShow = (identifier: string) => {
  const className = `${identifier}-modal`;
  const modal = document.querySelector(`.${className}`);
  if (modal) {
    modal.classList.add(`block`);
    modal.classList.remove(`hidden`);
  } else {
    console.warn(`Unable to show modal '${className}', not found`);
  }
};
