'use client';

import { addFavorite, removeFavorite } from "@/app/actions/favorite";
import { Heart } from "@/components/private/heart";
import { useState } from "react";

export default function VideoFavoriteClient({
  active: initial,
  className: initialClassName,
  videoId,
}: {
  active: boolean,
  className: string,
  videoId: number
}) {
  const [active, setActive,] = useState(initial);
  const [inProgress, setInProgress,] = useState(false);
  function onClick() {
    setInProgress(true);
    let promise;
    if (active) {
      setActive(false);
      promise = removeFavorite(videoId);
    } else {
      setActive(true);
      promise = addFavorite(videoId);
    }
    promise.then(() => setInProgress(false));
  }

  const iClassName = initialClassName ? ` ${initialClassName}` : ``;
  const className = inProgress ? `cursor-progress${iClassName}` : `pointer${iClassName}`;

  return (
    <Heart
      className={active ? `active ${className ? ` ${className}` : ``}` : className}
      onClick={() => !inProgress && onClick()} />
  );
}
