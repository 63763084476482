'use client';

import { useRouter } from 'next/navigation';
import { getClasses } from '@/classes';
import { Component, StyledComponent } from '@/shared';

export interface BackButtonComponent extends StyledComponent {
  text: string
}

function checkBackButtonComponent(component: Component): BackButtonComponent {
  const { text, ...props } = component as BackButtonComponent;

  if (!text) {
    throw new Error(`no text in modal-button component`);
  }

  return {
    ...props,
    text,
  };
}

export default function BackButton({ component, }: { component: Component }) {
  const { text, styles: { data: styles, }, } = checkBackButtonComponent(component);
  const router = useRouter();

  return (
    <button className={getClasses(styles)} onClick={() => router.back()}>{text}</button>
  );
}
